export default {
  teamFromGroup: function (groups, id) {
    for (const groupKey in groups) {
      const group = groups[groupKey];
      const team = this.teamFromTeams(group.teams, id);
      if(null !== team) {
        return team;
      }
    }
    return null;
  },
  teamFromTeams: function (teams, id) {
    for (const teamKey in teams) {
      const team = teams[teamKey]
      if(team.id === id) {
        return team;
      }
    }
    return null;
  },
  updatePoints: function(group) {
    for (const teamKey in group.teams) {
      const team = group.teams[teamKey]
      team.points = parseInt(0);
      team.goal = parseInt(0);
      team.fail = parseInt(0);
    }
    for (const matchKey in group.matches) {
      const match = group.matches[matchKey];
      if(match.updated) {
        const receiver = this.teamFromTeams(group.teams, match.receiver);
        const visitor = this.teamFromTeams(group.teams, match.visitor);
        receiver.goal += parseInt(match.score.receiver);
        receiver.fail += parseInt(match.score.visitor);
        visitor.goal += parseInt(match.score.visitor);
        visitor.fail += parseInt(match.score.receiver);
        if(parseInt(match.score.receiver) > parseInt(match.score.visitor)) {
          receiver.points += parseInt(3);
        } else if(parseInt(match.score.receiver) < parseInt(match.score.visitor)) {
          visitor.points += parseInt(3);
        } else {
          receiver.points += parseInt(1);
          visitor.points += parseInt(1);
        }
      }
    }
  },
  sort : function (teams) {
    teams.sort(function (a, b) {
      return (parseInt(b.goal) - parseInt(b.fail)) - (parseInt(a.goal) - parseInt(a.fail));
    });
    teams.sort(function (a, b) {
      return parseInt(b.points) - parseInt(a.points);
    });
    return teams;
  },
  data: function() {
    return [{
      id:'1',
      name: 'Groupe A',
      teams: [{
        id:'1', code:'qa', name: 'Qatar', points:0, goal: 0, fail: 0
      },{
        id:'2', code:'ec', name: 'Ecuador', points:0, goal: 0, fail: 0
      },{
        id:'3', code:'sn', name: 'Senegal', points:0, goal: 0, fail: 0
      },{
        id:'4', code:'nl', name: 'Netherlands', points:0, goal: 0, fail: 0
      },],
      matches: [
        {id: 1, date: '21/11/2022', receiver: '1', visitor: '2', updated:false, score: {receiver: 0, visitor: 0}},
        {id: 2, date: '21/11/2022', receiver: '3', visitor: '4', updated:false, score: {receiver: 0, visitor: 0}},
        {id: 3, date: '25/11/2022', receiver: '1', visitor: '3', updated:false, score: {receiver: 0, visitor: 0}},
        {id: 4, date: '25/11/2022', receiver: '4', visitor: '2', updated:false, score: {receiver: 0, visitor: 0}},
        {id: 5, date: '29/11/2022', receiver: '4', visitor: '1', updated:false, score: {receiver: 0, visitor: 0}},
        {id: 6, date: '29/11/2022', receiver: '2', visitor: '3', updated:false, score: {receiver: 0, visitor: 0}},
      ],
    },{
      id:'2',
      name: 'Groupe B',
      teams: [{
        id:'5', code:'gb-eng', name: 'England', points:0, goal: 0, fail: 0
      },{
        id:'6', code:'ir', name: 'IR Iran', points:0, goal: 0, fail: 0
      },{
        id:'7', code:'us', name: 'USA', points:0, goal: 0, fail: 0
      },{
        id:'8', code:'??', name: 'Euro Play-off', points:0, goal: 0, fail: 0
      },],
      matches: [
        {id: 7, date: '21/11/2022', receiver: '5', visitor: '6', updated:false, score: {receiver: 0, visitor: 0}},
        {id: 8, date: '21/11/2022', receiver: '7', visitor: '8', updated:false, score: {receiver: 0, visitor: 0}},
        {id: 9, date: '25/11/2022', receiver: '5', visitor: '7', updated:false, score: {receiver: 0, visitor: 0}},
        {id: 10, date: '25/11/2022', receiver: '8', visitor: '6', updated:false, score: {receiver: 0, visitor: 0}},
        {id: 11, date: '29/11/2022', receiver: '8', visitor: '5', updated:false, score: {receiver: 0, visitor: 0}},
        {id: 12, date: '29/11/2022', receiver: '6', visitor: '7', updated:false, score: {receiver: 0, visitor: 0}},
      ],
    },{
      id:'3',
      name: 'Groupe C',
      teams: [{
        id:'9', code:'ar', name: 'Argentina', points:0, goal: 0, fail: 0
      },{
        id:'10', code:'sa', name: 'Saudi Arabia', points:0, goal: 0, fail: 0
      },{
        id:'11', code:'mx', name: 'Mexico', points:0, goal: 0, fail: 0
      },{
        id:'12', code:'pl', name: 'Poland', points:0, goal: 0, fail: 0
      },],
      matches: [
        {id: 13, date: '22/11/2022', receiver: '9', visitor: '10', updated:false, score: {receiver: 0, visitor: 0}},
        {id: 14, date: '22/11/2022', receiver: '11', visitor: '12', updated:false, score: {receiver: 0, visitor: 0}},
        {id: 15, date: '26/11/2022', receiver: '9', visitor: '11', updated:false, score: {receiver: 0, visitor: 0}},
        {id: 16, date: '26/11/2022', receiver: '12', visitor: '10', updated:false, score: {receiver: 0, visitor: 0}},
        {id: 17, date: '30/11/2022', receiver: '12', visitor: '9', updated:false, score: {receiver: 0, visitor: 0}},
        {id: 18, date: '30/11/2022', receiver: '10', visitor: '11', updated:false, score: {receiver: 0, visitor: 0}},
      ],
    },{
      id:'4',
      name: 'Groupe D',
      teams: [{
        id:'13', code:'fr', name: 'France', points:0, goal: 0, fail: 0
      },{
        id:'14', code:'??', name: 'IC Play-off 1', points:0, goal: 0, fail: 0
      },{
        id:'15', code:'dk', name: 'Denmark', points:0, goal: 0, fail: 0
      },{
        id:'16', code:'tn', name: 'Tunisia', points:0, goal: 0, fail: 0
      },],
      matches: [
        {id: 19, date: '22/11/2022', receiver: '13', visitor: '14', updated:false, score: {receiver: 0, visitor: 0}},
        {id: 20, date: '22/11/2022', receiver: '15', visitor: '16', updated:false, score: {receiver: 0, visitor: 0}},
        {id: 21, date: '26/11/2022', receiver: '13', visitor: '15', updated:false, score: {receiver: 0, visitor: 0}},
        {id: 22, date: '26/11/2022', receiver: '16', visitor: '14', updated:false, score: {receiver: 0, visitor: 0}},
        {id: 23, date: '30/11/2022', receiver: '16', visitor: '13', updated:false, score: {receiver: 0, visitor: 0}},
        {id: 24, date: '30/11/2022', receiver: '14', visitor: '15', updated:false, score: {receiver: 0, visitor: 0}},
      ],
    }, {
      id: '5',
      name: 'Groupe E',
      teams: [{
        id: '17', code:'es', name: 'Spain', points:0, goal: 0, fail: 0
      }, {
        id: '18', code:'??', name: 'IC Play-off 2', points:0, goal: 0, fail: 0
      }, {
        id: '19', code:'de', name: 'Germany', points:0, goal: 0, fail: 0
      }, {
        id: '20', code:'jp', name: 'Japan', points:0, goal: 0, fail: 0
      },],
      matches: [
        {id: 25, date: '23/11/2022', receiver: '17', visitor: '18', updated:false, score: {receiver: 0, visitor: 0}},
        {id: 26, date: '23/11/2022', receiver: '19', visitor: '20', updated:false, score: {receiver: 0, visitor: 0}},
        {id: 27, date: '27/11/2022', receiver: '17', visitor: '19', updated:false, score: {receiver: 0, visitor: 0}},
        {id: 28, date: '27/11/2022', receiver: '20', visitor: '18', updated:false, score: {receiver: 0, visitor: 0}},
        {id: 29, date: '01/12/2022', receiver: '20', visitor: '17', updated:false, score: {receiver: 0, visitor: 0}},
        {id: 30, date: '01/12/2022', receiver: '18', visitor: '19', updated:false, score: {receiver: 0, visitor: 0}},
      ],
    },{
      id:'6',
      name: 'Groupe F',
      teams: [{
        id:'21', code:'be', name: 'Belgium', points:0, goal: 0, fail: 0
      },{
        id:'22', code:'ca', name: 'Canada', points:0, goal: 0, fail: 0
      },{
        id:'23', code:'ma', name: 'Morocco', points:0, goal: 0, fail: 0
      },{
        id:'24', code:'hr', name: 'Croatia', points:0, goal: 0, fail: 0
      },],
      matches: [
        {id: 31, date: '23/11/2022', receiver: '21', visitor: '22', updated:false, score: {receiver: 0, visitor: 0}},
        {id: 32, date: '23/11/2022', receiver: '23', visitor: '24', updated:false, score: {receiver: 0, visitor: 0}},
        {id: 33, date: '27/11/2022', receiver: '21', visitor: '23', updated:false, score: {receiver: 0, visitor: 0}},
        {id: 34, date: '27/11/2022', receiver: '24', visitor: '22', updated:false, score: {receiver: 0, visitor: 0}},
        {id: 35, date: '01/12/2022', receiver: '24', visitor: '21', updated:false, score: {receiver: 0, visitor: 0}},
        {id: 36, date: '01/12/2022', receiver: '22', visitor: '23', updated:false, score: {receiver: 0, visitor: 0}},
      ],
    },{
      id:'7',
      name: 'Groupe G',
      teams: [{
        id:'25', code:'br', name: 'Brazil', points:0, goal: 0, fail: 0
      },{
        id:'26', code:'rs', name: 'Serbia', points:0, goal: 0, fail: 0
      },{
        id:'27', code:'ch', name: 'Switzerland', points:0, goal: 0, fail: 0
      },{
        id:'28', code:'cm', name: 'Cameroon', points:0, goal: 0, fail: 0
      },],
      matches: [
        {id: 37, date: '24/11/2022', receiver: '25', visitor: '26', updated:false, score: {receiver: 0, visitor: 0}},
        {id: 38, date: '24/11/2022', receiver: '27', visitor: '28', updated:false, score: {receiver: 0, visitor: 0}},
        {id: 39, date: '28/11/2022', receiver: '25', visitor: '27', updated:false, score: {receiver: 0, visitor: 0}},
        {id: 40, date: '28/11/2022', receiver: '28', visitor: '26', updated:false, score: {receiver: 0, visitor: 0}},
        {id: 41, date: '02/12/2022', receiver: '28', visitor: '25', updated:false, score: {receiver: 0, visitor: 0}},
        {id: 42, date: '02/12/2022', receiver: '26', visitor: '27', updated:false, score: {receiver: 0, visitor: 0}},
      ],
    },{
      id:'8',
      name: 'Groupe H',
      teams: [{
        id:'29', code:'pt', name: 'Portugal', points:0, goal: 0, fail: 0
      },{
        id:'30', code:'gh', name: 'Ghana', points:0, goal: 0, fail: 0
      },{
        id:'31', code:'uy', name: 'Uruguay', points:0, goal: 0, fail: 0
      },{
        id:'32', code:'kr', name: 'Korea Republic', points:0, goal: 0, fail: 0
      },],
      matches: [
        {id: 43, date: '24/11/2022', receiver: '29', visitor: '30', updated:false, score: {receiver: 0, visitor: 0}},
        {id: 44, date: '24/11/2022', receiver: '31', visitor: '32', updated:false, score: {receiver: 0, visitor: 0}},
        {id: 45, date: '28/11/2022', receiver: '29', visitor: '31', updated:false, score: {receiver: 0, visitor: 0}},
        {id: 46, date: '28/11/2022', receiver: '32', visitor: '30', updated:false, score: {receiver: 0, visitor: 0}},
        {id: 47, date: '02/12/2022', receiver: '32', visitor: '29', updated:false, score: {receiver: 0, visitor: 0}},
        {id: 48, date: '02/12/2022', receiver: '30', visitor: '31', updated:false, score: {receiver: 0, visitor: 0}},
      ],
    },
    ];
  }
}
