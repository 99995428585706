export default {
  fr: {
    application: {
      name: 'MonProno',
      heading: 'MonProno',
      subheading: 'Pronostiques entre amis',
    },
    menu: {
      home: 'Accueil',
      about: 'A propos de',
      login: 'Se connecter',
      subscribe: 'S\'inscrire',
    },
    group : {
      matchs : {
        list : 'Liste des matchs'
      }
    },
    action : {
      cancel : 'Annuler',
      validate : 'Valider',
    },
    phase : {
      group : 'Phase de poule',
      final : 'Phase finale',
    }
  }
}
