<template>
    <!-- Header-->
    <header class="masthead text-center text-white">
        <div class="masthead-content">
            <div class="container px-5">
                <h1 class="masthead-heading mb-0">{{$t('application.heading')}}</h1>
                <h2 class="masthead-subheading mb-0">{{$t('application.subheading')}}</h2>
            </div>
        </div>
        <div class="bg-circle-1 bg-circle"></div>
        <div class="bg-circle-2 bg-circle"></div>
        <div class="bg-circle-3 bg-circle"></div>
        <div class="bg-circle-4 bg-circle"></div>
    </header>
</template>
<script>
    export default {
        name: "Header"
    }
</script>
<style scoped>
</style>
