<template>
    <div>
        <b-card :title="date(match)" footer-tag="footer" v-if="match">
            <b-card-text>
                <b-container>
                    <b-row>
                        <b-col cols="4"  class="p-0 m-0">
                            <match-team :team="team(match.receiver)" :class="receiverClass()" v-if="match.receiver"/>
                        </b-col>
                        <b-col cols="4">
                            <b-row>
                                <b-col cols="6" class="p-2 m-0">
                                    <b-form-group>
                                        <b-form-input v-model="match.score.receiver" placeholder="0" type="number" min="0" :formatter="formatter" class="score" :readonly="match.receiver ? false : true"/>
                                    </b-form-group>
                                </b-col>
                                <b-col cols="6" class="p-2 m-0">
                                    <b-form-group>
                                        <b-form-input v-model="match.score.visitor" placeholder="0" type="number" min="0" :formatter="formatter" class="score" :readonly="match.visitor ? false : true"/>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                        </b-col>
                        <b-col cols="4" class="p-0 m-0">
                            <match-team :team="team(match.visitor)" :class="visitorClass()" v-if="match.visitor"/>
                        </b-col>
                    </b-row>
                </b-container>
            </b-card-text>
        </b-card>
    </div>
</template>
<script>
    import MatchTeam from "./MatchTeam";
    import GroupUtils from "../utils/groups";

    export default {
        name: "CleaverMatch",
        components: {MatchTeam},
        props: {
            match: Object,
            teams: Array,
        },
        watch: {
            'match.matchReceiver.score.receiver': function()  {
                this.update();
            },
            'match.matchReceiver.score.visitor': function()  {
                this.update();
            },
            'match.matchVisitor.score.receiver': function()  {
                this.update();
            },
            'match.matchVisitor.score.visitor': function()  {
                this.update();
            },
        },
        mounted() {
            this.update();
        },
        methods: {
            date : function (match) {
                return match.date ?? "";
            },
            team : function (id) {
                return GroupUtils.teamFromTeams(this.teams, id);
            },
            update : function () {
                if(this.match){
                    if(this.match.matchReceiver) {
                        this.match.receiver = this.match.matchReceiver.score.receiver > this.match.matchReceiver.score.visitor ? this.match.matchReceiver.receiver :
                            this.match.matchReceiver.score.receiver < this.match.matchReceiver.score.visitor ? this.match.matchReceiver.visitor : 0;
                        if(this.match.receiver === 0) {
                            this.match.score.receiver = 0;
                        }
                    }
                    if(this.match.matchVisitor) {
                        this.match.visitor = this.match.matchVisitor.score.receiver > this.match.matchVisitor.score.visitor ? this.match.matchVisitor.receiver :
                            this.match.matchVisitor.score.receiver < this.match.matchVisitor.score.visitor ? this.match.matchVisitor.visitor : 0;
                        if(this.match.visitor === 0) {
                            this.match.score.visitor = 0;
                        }
                    }
                }
            },
            formatter(value) {
                return parseInt(value);
            },
            receiverClass() {
                return parseInt(this.match.score.receiver) > parseInt(this.match.score.visitor) ? 'winner' : '';
            },
            visitorClass() {
                return parseInt(this.match.score.receiver) < parseInt(this.match.score.visitor) ? 'winner' : '';
            }
        }
    }
</script>
<style scoped>
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    input[type=number] {
        -moz-appearance: textfield;
    }
</style>
