import Vue from 'vue'
import Vuex from 'vuex'
import GroupUtils from "../utils/groups";

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        groups: [],
    },
    mutations: {
        UPDATE_GROUPS(state, groups) {
            state.groups = groups
        }
    },
    getters: {
        groups: state => state.groups
    },
    actions: {
        load (context) {
            const json = localStorage.getItem('GROUPS');
            const groups = json ? JSON.parse(json) : GroupUtils.data();
            context.commit('UPDATE_GROUPS', groups);
        },
        update (context, group) {
            for (const key in context.state.groups) {
                if(context.state.groups[key].id === group.id) {
                    context.state.groups[key] = group;
                }
            }
            context.commit('UPDATE_GROUPS', context.state.groups);
            localStorage.setItem('GROUPS', JSON.stringify(context.state.groups));
        },
    }
})
