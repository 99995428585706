<template>
    <div class="group col-12 col-sm-6 col-md-4 col-lg-3">
        <b-card header-tag="header" footer-tag="footer">
            <template #header>
                <h4 class="mb-0 float-left">{{group.name}}</h4>
                <b-icon-check font-scale="1.7" v-if="validated" variant="success"></b-icon-check>
                <b-button class="float-right btn-sm" :variant="validated ? 'outline-success' : 'outline-danger'" @click="showModal">
                    <b-icon-search></b-icon-search>
                </b-button>
            </template>
            <b-card-text>
                <b-table hover :items="tabled" :fields="fields" small borderless>
                    <template #cell(flag)="data">
                        <country-flag :country='data.item.code' size='normal' v-if="data.item.code"/>
                    </template>
                    <template #cell(diff)="data">
                        {{data.item.goal - data.item.fail}}
                    </template>
                </b-table>
            </b-card-text>
        </b-card>
        <b-modal :id="groupId(group)" :title="group.name" hide-footer :ref="groupId(group)" no-close-on-backdrop no-close-on-esc size="xl">
            <h4>{{$t('group.matchs.list')}}</h4>
            <matches :group="group"/>
            <footer class="modal-footer">
                <b-button class="btn" variant="outline-danger" @click="cancelGroup">{{$t('action.cancel')}}</b-button>
                <b-button class="btn" variant="outline-success" @click="updateGroup">{{$t('action.validate')}}</b-button>
            </footer>
        </b-modal>
    </div>
</template>
<script>
    import Matches from "./Matches";
    import CountryFlag from 'vue-country-flag'
    import {mapActions} from "vuex";
    import GroupUtils from "../utils/groups";
    export default {
        name: "Group",
        components: {
            Matches,
            CountryFlag,
        },
        props: {
            group: Object,
        },
        data() {
            return {
                previous: null,
                fields: [
                    { key: 'flag', label: '' },
                    { key: 'name', label: '' },
                    { key: 'points', label: '' },
                    { key: 'diff', label: '' }
                ],
            }
        },
        computed: {
            tabled : function() {
                return this.sort(this.group.teams);
            },
            validated : function() {
                return this.group.matches.filter(value => value.updated).length === this.group.matches.length;
            }
        },
        methods: {
            ...mapActions({
                update: 'update',
            }),
            groupId : function (group) {
                return 'group-' + group.id;
            },
            sort : function (teams) {
                return GroupUtils.sort(teams);
            },
            hideModal() {
                const modalId = this.groupId(this.group);
                this.$refs[modalId].hide();
            },
            showModal() {
                this.previous = this.group;
                const modalId = this.groupId(this.group);
                this.$bvModal.show(modalId);
            },
            cancelGroup() {
                if(null !== this.previous && this.group.id === this.previous.id) {
                    this.group = this.previous;
                    GroupUtils.updatePoints(this.group);
                    this.update(this.group);
                }
                this.hideModal();
            },
            updateGroup() {
                this.group.matches.forEach(element => element.updated = true);
                GroupUtils.updatePoints(this.group);
                this.update(this.group);
                this.hideModal();
            },
        }
    }
</script>
<style scoped>
    .group{
        padding: 8px;
    }
</style>
