<template>
  <div id="app">
    <NavigationBar/>
    <Header/>
    <b-tabs pills card align="center">
      <b-tab :title="$t('phase.group')">
        <group-phase :groups="groups"/>
      </b-tab>
      <b-tab :title="$t('phase.final')" lazy v-if="!disabled">
        <final-phase :groups="groups"/>
      </b-tab>
      <b-tab :title="$t('phase.final')" lazy disabled v-if="disabled"/>
    </b-tabs>
  </div>
</template>
<script>
  import { mapState, mapActions } from 'vuex'
  import Header from './components/Header'
  import GroupPhase from './components/GroupPhase'
  import FinalPhase from "./components/FinalPhase"
  import NavigationBar from './components/NavigationBar'

  export default {
    components: {
      FinalPhase,
      NavigationBar,
      Header,
      GroupPhase
    },
    computed: {
      ...mapState([
        'groups',
      ]),
      disabled: function() {
        let total = 0;
        let updated = 0;
        this.groups.forEach(group => {
          group.matches.forEach(match => {
            updated += match.updated ? 1 : 0;
            total++;
          })
        });
        return total !== updated;
      }
    },
    mounted() {
      this.load();
    },
    methods: {
      ...mapActions({
        load: 'load',
      }),
    }
  }
</script>
<style>
</style>
