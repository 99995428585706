<template>
    <div>
        <b-row  align-v="center">
            <b-col class="eight">
                <cleaver-match :match="match" v-for="(match, m) in eights" :key="m" :teams="teams"/>
            </b-col>
            <b-col class="quarter">
                <cleaver-match :match="match" v-for="(match, m) in quarter" :key="m" :teams="teams"/>
            </b-col>
            <b-col class="semi">
                <cleaver-match :match="match" v-for="(match, m) in semi" :key="m" :teams="teams"/>
            </b-col>
            <b-col class="final">
                <cleaver-match :match="match" v-for="(match, m) in final" :key="m" :teams="teams"/>
            </b-col>
        </b-row>
    </div>
</template>
<script>
    import GroupUtils from "../utils/groups";
    import CleaverMatch from "./CleaverMatch";

    export default {
        name: "FinalPhase",
        components: {
            CleaverMatch
        },
        props: {
            groups: Array,
        },
        data() {
            return {
                matchs : [],
            }
        },
        mounted() {
            this.init();
        },
        computed: {
            eights : function () {
                return [
                    this.matchs[0],
                    this.matchs[1],
                    this.matchs[2],
                    this.matchs[3],
                    this.matchs[4],
                    this.matchs[5],
                    this.matchs[6],
                    this.matchs[7],
                ];
            },
            quarter : function () {
                return [
                    this.matchs[8],
                    this.matchs[9],
                    this.matchs[10],
                    this.matchs[11],
                ];
            },
            semi : function () {
                return [
                    this.matchs[12],
                    this.matchs[13],
                ];
            },
            third : function () {
                return [
                    this.matchs[14],
                ];
            },
            final : function () {
                return [
                    this.matchs[15],
                ];
            },
            teams : function() {
                return [].concat(
                    this.groups.length > 0 ? this.groups[0].teams : [],
                    this.groups.length > 1 ? this.groups[1].teams : [],
                    this.groups.length > 2 ? this.groups[2].teams : [],
                    this.groups.length > 3 ? this.groups[3].teams : [],
                    this.groups.length > 4 ? this.groups[4].teams : [],
                    this.groups.length > 5 ? this.groups[5].teams : [],
                    this.groups.length > 6 ? this.groups[6].teams : [],
                    this.groups.length > 7 ? this.groups[7].teams : [],
                );
            },
        },
        methods: {
            init : function() {
                this.matchs = [
                    this.from(1, '03/12/2022', 0, 0, 1, 1),
                    this.from(2, '03/12/2022', 2, 0, 3, 1),
                    this.from(3, '03/12/2022', 4, 0, 5, 1),
                    this.from(4, '03/12/2022', 6, 0, 7, 1),
                    this.from(5, '03/12/2022', 1, 0, 0, 1),
                    this.from(6, '03/12/2022', 3, 0, 2, 1),
                    this.from(7, '03/12/2022', 5, 0, 4, 1),
                    this.from(8, '03/12/2022', 7, 0, 6, 1),
                ];
                this.matchs.push(
                    this.match(9, '09/12/2022', true, this.matchs[0], this.matchs[1]),
                    this.match(10, '09/12/2022', true, this.matchs[2], this.matchs[3]),
                    this.match(11, '10/12/2022', true, this.matchs[4], this.matchs[5]),
                    this.match(12, '10/12/2022', true, this.matchs[6], this.matchs[7]),
                );
                this.matchs.push(
                    this.match(13, '13/12/2022', true, this.matchs[8], this.matchs[9]),
                    this.match(14, '14/12/2022', true, this.matchs[10], this.matchs[11]),
                );
                this.matchs.push(
                    this.match(15, '17/12/2022', false, this.matchs[12], this.matchs[13]),
                );
                this.matchs.push(
                    this.match(16, '18/12/2022', true, this.matchs[12], this.matchs[13]),
                );
            },
            from: function(id, date, receiverGroup, receiverPosition, visitorGroup, visitorPosition) {
                const receiverTeams = this.groups.length > receiverGroup && this.groups[receiverGroup].teams.length > receiverPosition ? Object.assign([], this.groups[receiverGroup].teams) : null;
                const receiver = receiverTeams ? GroupUtils.sort(receiverTeams)[receiverPosition].id : 0;
                const visitorTeams = this.groups.length > visitorGroup && this.groups[visitorGroup].teams.length > visitorPosition ? Object.assign([], this.groups[visitorGroup].teams) : null;
                const visitor = visitorTeams ? GroupUtils.sort(visitorTeams)[visitorPosition].id : 0;
                return {
                    id: id,
                    date: date,
                    receiver: receiver,
                    visitor: visitor,
                    updated:false,
                    score: {receiver: 0, visitor: 0,}
                }
            },
            match: function(id, date, winner, matchReceiver, matchVisitor) {
                return {
                    id: id,
                    date: date,
                    receiver: 0,
                    visitor: 0,
                    winner: winner,
                    matchReceiver: matchReceiver,
                    matchVisitor: matchVisitor,
                    updated: false,
                    score: {receiver: 0, visitor: 0,},
                }
            },
        }
    }
</script>
<style scoped>
</style>
