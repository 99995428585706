<template>
    <div>
        <b-card :title="date(match)" footer-tag="footer">
            <b-card-text>
                <b-container>
                    <b-row>
                        <b-col cols="4"  class="p-0 m-0">
                            <match-team :team="team(match.receiver)" :class="receiverClass()"/>
                        </b-col>
                        <b-col cols="4">
                            <b-row>
                                <b-col cols="4" class="p-0 m-0">
                                    <b-form-group>
                                        <b-form-input v-model="match.score.receiver" placeholder="0" type="number" min="0" :formatter="formatter" class="score"/>
                                    </b-form-group>
                                </b-col>
                                <b-col cols="4" class="p-0 m-0 text-center">
                                    <span class="separator">:</span>
                                </b-col>
                                <b-col cols="4" class="p-0 m-0">
                                    <b-form-group>
                                        <b-form-input v-model="match.score.visitor" placeholder="0" type="number" min="0" :formatter="formatter" class="score"/>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                        </b-col>
                        <b-col cols="4" class="p-0 m-0">
                            <match-team :team="team(match.visitor)" :class="visitorClass()"/>
                        </b-col>
                    </b-row>
                </b-container>
            </b-card-text>
        </b-card>
    </div>
</template>
<script>
    import GroupUtils from "../utils/groups";
    import MatchTeam from "./MatchTeam";
    import {mapActions} from "vuex";
    export default {
        name: "Match",
        components: {MatchTeam},
        props: {
            match: Object,
            group: Object,
        },
        watch: {
            'match.score.receiver': function()  {
                this.match.updated = true;
                GroupUtils.updatePoints(this.group);
                this.update(this.group);
            },
            'match.score.visitor': function()  {
                this.match.updated = true;
                GroupUtils.updatePoints(this.group);
                this.update(this.group);
            },
        },
        methods: {
            ...mapActions({
                update: 'update',
            }),
            date : function (match) {
                return match.date;
            },
            team : function (id) {
                return GroupUtils.teamFromTeams(this.group.teams, id);
            },
            formatter(value) {
                return parseInt(value);
            },
            receiverClass() {
                return parseInt(this.match.score.receiver) > parseInt(this.match.score.visitor) ? 'winner' : '';
            },
            visitorClass() {
                return parseInt(this.match.score.receiver) < parseInt(this.match.score.visitor) ? 'winner' : '';
            }
        }
    }
</script>
<style scoped>
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    input[type=number] {
        -moz-appearance: textfield;
    }
</style>
