<template>
    <div class="team">
        <div class="flag">
            <country-flag :country='team.code' size='normal' v-if="team.code"/>
        </div>
        <p class="name">{{team.name}}</p>
    </div>
</template>
<script>
    import CountryFlag from 'vue-country-flag'
    export default {
        name: "MatchTeam",
        components: {
            CountryFlag,
        },
        props: {
            team: Object,
        }
    }
</script>
<style scoped>
    .team {
        padding: 8px;
        text-align: center;
    }
    .flag {
        margin: auto;
        padding: 0;
        border: 0;
    }
    .name {
        margin: 0;
        padding: 0;
        border: 0;
        vertical-align: baseline;
        font-family: inherit;
        font-size: 16px;
        font-weight: 500;
        align-self: center;
        text-align: center;
        white-space: nowrap;
    }
</style>
