<template>
    <!-- Navigation-->
    <b-navbar toggleable="lg" type="dark" variant="dark">
        <b-navbar-brand href="#">{{$t('application.name')}}</b-navbar-brand>
        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
        <b-collapse id="nav-collapse" is-nav>
            <b-navbar-nav  class="ml-auto">
                <b-nav-item>{{$t('menu.login')}}</b-nav-item>
                <b-nav-item>{{$t('menu.subscribe')}}</b-nav-item>
            </b-navbar-nav>
        </b-collapse>
    </b-navbar>
</template>
<script>
    export default {
        name: "NavigationBar"
    }
</script>
<style scoped>
</style>
