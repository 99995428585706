<template>
    <div class="row">
        <match :match="match" v-for="(match, m) in group.matches" :key="m" :group="group" class="col-12 col-lg-6"/>
    </div>
</template>

<script>
    import Match from "./Match";
    export default {
        name: "Matches",
        components: {
            Match,
        },
        props: {
            group: Object,
        },
    }
</script>

<style scoped>

</style>
