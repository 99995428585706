import Vue from 'vue'
import VueI18n from 'vue-i18n'
import fr from './locales/fr.js'

Vue.use(VueI18n)

const messages = Object.assign({}, fr)

export default new VueI18n({
  locale: navigator.language,
  fallbackLocale: 'fr',
  messages
})
