<template>
    <b-container>
        <b-row>
            <group :group="group" v-for="(group, g) in groups" :key="g"/>
        </b-row>
    </b-container>
</template>
<script>
    import Group from "./Group";
    export default {
        name: "GroupPhase",
        components: {
            Group,
        },
        props: {
            groups: Array,
        }
    }
</script>
<style scoped>
</style>
